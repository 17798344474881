import { Box, Typography } from '@mui/material';
import React from 'react';

export default function Footer() {
  // const theme = useTheme();
  return (
    <Box
      sx={{
        height: '10vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body2">Powered By Arton © 2024</Typography>
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
          [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
          },
          [theme.breakpoints.between('sm', 'md')]: {
            width: '50%',
          },
          [theme.breakpoints.between('md', 'lg')]: {
            width: '50%',
          },
          [theme.breakpoints.between('lg', 'xl')]: {
            width: '40%',
          },
          [theme.breakpoints.up('xl')]: {
            width: '35%',
          },
        }}
      >
        <Typography variant="body2">License</Typography>
        <Typography variant="body2">More Themes</Typography>
        <Typography variant="body2">Documentation</Typography>
        <Typography variant="body2">Support</Typography>
      </Box> */}
    </Box>
  );
}
